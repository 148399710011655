var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [(invalid || !invalid)?_c('div',[_vm._v(" "+_vm._s(_vm.setInvalidElements(invalid))+" ")]):_vm._e(),_c('v-row',{staticClass:"px-3"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"v-card-blue mb-3 px-0 "},[_c('v-row',{staticClass:"min-height"},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6","lg":"6","xl":"6"}},[_c('v-card-text',[_c('v-row',[_c('forms-color',{attrs:{"form":_vm.form,"item":_vm.form.header}}),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Nombre del formulario'),"rules":"required|max:64"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"text","name":'field_' + _vm.randomizeKey(),"color":"light-blue darken-2","background-color":"blue-grey lighten-5","filled":"","label":_vm.$t('Nombre del formulario'),"error-messages":errors},model:{value:(_vm.form.header.title),callback:function ($$v) {_vm.$set(_vm.form.header, "title", $$v)},expression:"form.header.title"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":_vm.$t('descripción'),"rules":"max:512"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"name":'field_' + _vm.randomizeKey(),"color":"light-blue darken-2","background-color":"blue-grey lighten-5","filled":"","label":_vm.$t('Descripción'),"rows":"2","row-height":"20","error-messages":errors},model:{value:(_vm.form.header.description),callback:function ($$v) {_vm.$set(_vm.form.header, "description", $$v)},expression:"form.header.description"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":_vm.$t('botón enviar'),"rules":"max:64"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"text","name":'field_' + _vm.randomizeKey(),"color":"light-blue darken-2","background-color":"blue-grey lighten-5","filled":"","label":_vm.$t('Botón enviar'),"placeholder":_vm.$t('Enviar'),"persistent-placeholder":true,"error-messages":errors},model:{value:(_vm.form.properties.buttonText),callback:function ($$v) {_vm.$set(_vm.form.properties, "buttonText", $$v)},expression:"form.properties.buttonText"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":_vm.$t('texto de agradecimiento'),"rules":"max:128"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"name":'field_' + _vm.randomizeKey(),"color":"light-blue darken-2","background-color":"blue-grey lighten-5","filled":"","label":_vm.$t('Texto de agradecimiento al completar el formulario'),"rows":"2","row-height":"20","placeholder":_vm.$t('Gracias por enviar las respuestas'),"persistent-placeholder":true,"error-messages":errors},model:{value:(_vm.form.properties.thanksText),callback:function ($$v) {_vm.$set(_vm.form.properties, "thanksText", $$v)},expression:"form.properties.thanksText"}})]}}],null,true)})],1)],1)],1),_c('v-list',{attrs:{"three-line":""}},[_c('drop-list',{attrs:{"items":_vm.form.sections[0].items,"mode":"cut"},on:{"reorder":function($event){return $event.apply(_vm.form.sections[0].items)}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('drag',{key:_vm.form.sections[0].items.indexOf(item),attrs:{"data":item},on:{"cut":function($event){return _vm.remove(_vm.form.sections[0].items, item)}}},[(item.itemType == 'question')?_c('v-list-item',[_c('v-list-item-content',[_c('v-row',[_c('v-col',[_c('v-card',{staticClass:"card-shadow v-card-grey  pb-0 px-0 "},[_c('v-card-text',[_c('item-question',{attrs:{"form":_vm.form,"item":item,"fields":_vm.fields,"unsubscribe-pages":_vm.unsubscribePages}})],1),(item.itemType == 'question')?_c('v-divider'):_vm._e(),_c('card-actions',{attrs:{"form":_vm.form,"item":item}})],1)],1)],1)],1)],1):_vm._e()],1)]}}],null,true)})],1),_c('v-row',[_c('v-col',[_c('v-menu',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"block":"","color":"blue darken-3  white--text text-transform-none","elevation":"0","x-large":""}},'v-btn',attrs,false),on),[_c('v-icon',_vm._g({attrs:{"left":""}},on),[_vm._v(" fas fa-plus-circle ")]),_vm._v(" "+_vm._s(_vm.$t('Nueva pregunta'))+" ")],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item-group',_vm._l((_vm.questionType.getQuestionTypes()),function(qtype,indexNewType){return _c('v-list-item',{key:indexNewType,on:{"click":function($event){return _vm.newQuestion(qtype.value)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(" "+_vm._s(qtype.icon)+" ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(qtype.text)+" ")])],1)],1)}),1)],1)],1)],1)],1)],1),_c('v-col',{staticClass:"position-relative",attrs:{"sm":"12","md":"6","lg":"6","xl":"6"}},[_c('p',{staticClass:"text-caption"},[_vm._v(" "+_vm._s(_vm.$t('Preview'))+" ")]),(_vm.ready)?_c('iframe',{staticClass:"iframe-preview",class:{ 'iframe-fixed' : _vm.fixedMode, 'iframe-absolute' : _vm.absoluteMode},attrs:{"id":"topIframe","src":_vm.previewContent}}):_vm._e()]),_c('v-col',{attrs:{"sm":"12"}},[_c('div',{attrs:{"id":"finishIframe"}})]),_c('v-col',{attrs:{"cols":"12"}},[_c('save-bar',{staticClass:"ml-4 mr-4",attrs:{"save-bar-properties":_vm.saveBarProperties,"preview":false},on:{"save":_vm.saveOrUpdate,"change-name":_vm.changeTemplateName}})],1)],1)],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }