import MarginProperty from '../../common/margin-property/MarginProperty.vue.js'
import Editor from '@tinymce/tinymce-vue'
import UnsuscribeService from '@/services/unsuscribe.service.js'
import AccountService from '@/services/account.service.js'
import { Languages } from '@/classes/Languages.js'
import $t from '@/util/Translator'
import TextEditor from '@/components/TextEditor/TextEditor.vue'
import { CancelSubscription } from '@/components/Template/LandingEditor/classes/CancelSubscription.js'

export default {
  name: 'SimpleCancelSubscriptionProperties',
  components: {
    MarginProperty,
    Editor,
    TextEditor,
  },
  props: { landing: { type: Object, required: true } },
  data: function () {
    return {
      languages: Languages.languages.map(l => ({ value: l.iso, text: l.tooltip })),
      selectedLanguage: null,
      landingUnsubscribePages: [],
      renderEditor: false,
      user: this.$store.getters['auth/getUser'],
      component: new CancelSubscription(this.user?.isCountryEu || JSON.parse(localStorage.getItem('user')).isCountryEu),
    }
  },
  methods: {
    textChanged () {
      const parser = new DOMParser()
      const htmlDoc = parser.parseFromString(this.landing.htmlContent, 'text/html')
      const container = htmlDoc.getElementById('unsubscribe-container')

      if (![null, undefined].includes(container)) {
        container.innerHTML = this.component.tinymce
        const s = new XMLSerializer()
        const htmlDocString = s.serializeToString(htmlDoc)
        this.$emit('updatedHtmlCode', htmlDocString)
      }
    },
    async getProfile () {
      let profile = null
      await AccountService.getBilling()
      .then(
        (response) => {
          profile = response.billingProfiles[0]
        },
        () => {},
      )
      return profile
    },
    updatedHtmlContent () {
      const parser = new DOMParser()
      const htmlDoc = parser.parseFromString(this.landing.htmlContent, 'text/html')
      const container = htmlDoc.getElementById('unsubscribe-container')

      if (container === null || container === undefined) {
        htmlDoc.body.innerHTML = htmlDoc.body.innerHTML + '<div id="unsubscribe-container">' + this.component.tinymce + '</div>'
        const s = new XMLSerializer()
        const htmlDocString = s.serializeToString(htmlDoc)
        this.$emit('updatedHtmlCode', htmlDocString)
      } else {
        const link = htmlDoc.getElementById('unsusbcribeLink')
        if (link === null || link === undefined) {
          container.innerHTML = container.innerHTML + `<p style="text-align: ${this.component.alignment};"><a
            id="unsusbcribeLink"
            style="color: ${this.component.urlColor};"
            class="temp-disable-link-action"
            href="${this.component.redirectTo1}"
            target="_blank"
            contenteditable="false"
          >${this.component.text1}</a>
        </p>`
          const s = new XMLSerializer()
          const htmlDocString = s.serializeToString(htmlDoc)
          this.$emit('updatedHtmlCode', htmlDocString)
        }
      }
    },
    changeUrlAndLanguage () {
      const parser = new DOMParser()
      const htmlDoc = parser.parseFromString(this.component.tinymce, 'text/html')
      const link = htmlDoc.getElementById('unsusbcribeLink')

      if (link.href.search(this.component.redirectTo1) >= 0) {
        link.textContent = $t('Darse de baja', this.component.language)
        link.href = this.component.redirectTo1
        this.component.tinymce = htmlDoc.body.innerHTML
        this.$refs.cancelSubsEditor.resetEditorContent()
      }
    },
    getUnsubscribePages () {
      this.landingUnsubscribePages = []
      UnsuscribeService.getPagesOptions({ type: 'mailing' }).then((response) => {
        response.options.map((page) => {
          this.$set(this, 'landingUnsubscribePages', [
            ...this.landingUnsubscribePages,
            {
              value: `[unsubscribe_link_${page.id}]`,
              text: page.name,
            },
          ])
        })
      })
    },
    changeUnsubscribePage () {
      this.changeUrlAndLanguage()
    },
    isSelected (unsubscribePage) {
      if (unsubscribePage.value === this.component.redirectTo1) {
        return true
      }
      return false
    },
  },
  computed: {
    isDefaultSelected () {
      return this.component.redirectTo1 === '[unsubscribe_link]'
    },
  },
  created: async function () {
    if (this.component.tinymce === '') {
      const profile = await this.getProfile()
      if (profile) {
        this.component.secondaryText = `${profile.company_name} ${profile.CIF}<br>${profile.address} ${profile.CP} ${profile.city}`
      }

      this.component.tinymce = `<p style="text-align: ${this.component.alignment};font-size: ${this.component.mainTextFontSize}px;font-family: ${this.component.mainTextFont};color: ${this.component.mainTextColor};"
      > ${this.component.mainText}</p><p style="text-align: ${this.component.alignment};font-size: ${this.component.secondaryTextSize}px;font-family: ${this.component.secondaryTextFont};color: ${this.component.secondaryTextColor};font-family: Helvetica,Arial,sans-serif;
      ">${this.component.secondaryText}</p><p style="text-align: ${this.component.alignment};"><a
          id="unsusbcribeLink"
          style="color: ${this.component.urlColor};"
          class="temp-disable-link-action"
          href="${this.component.redirectTo1}"
          target="_blank"
          contenteditable="false"
        >${this.component.text1}</a>
      </p>`

      this.getUnsubscribePages()
    }
    this.renderEditor = true
  },
  mounted: function () {
    const that = this
    const elements = document.querySelectorAll('.tox .tox-edit-area__iframe')
    elements.forEach(function (elemen) {
      elemen.setAttribute('style', 'background-color: ' + that.landing.structure.containers[that.containerIndex].backgroundColor + ' !important;')
    })
    this.getUnsubscribePages()
  },
}
