import PhonesWithPrefix from '@/components/PhonesWithPrefix/PhonesWithPrefix.vue'
import EventBus from '@/util/EventBus.js'
import { ChannelType } from '@/models/ChannelType.js'
import PaymentService from '@/services/payment.service'
import AccountService from '@/services/account.service'

export default {
  name: 'TestModal',
  components: { PhonesWithPrefix },
  props: [
    'campaign',
    'countriesWithPhonePrefix',
    'phonesWithPrefix',
    'testEmailAddress',
    'title',
    'preview',
    'currentSmsIn',
  ],
  data: function () {
    return {
        sendTestDialog: false,
        channelMailing: ChannelType.MAILING_ID,
        smsPrice: 0,
        mailPrice: 0,
        smsPriceDataTable: [],
        mainSmsPrice: null,
        mainCertificateSmsPrice: null,
        mainMailPrice: null,
        balanceAlert: {},
    }
  },
  created () {
    this.getPlanPrices()
    this.setBalanceAlert()
  },
  mounted () {
    EventBus.$on('send-test-event', () => {
      this.sendTestDialog = true
    })

    EventBus.$on('close-test-dialogs', () => {
      this.sendTestDialog = false
    })
  },
  computed: {
    balance () {
      return this.$store.state.balance
    },
    calculatedBalance () {
      return this.balance.amount
    },
    hasNotEnoughBalance () {
      if (this.calculatedBalance <= 0) {
        return true
      }

      if (this.campaign.sendings[0].channel.channel_type_id === ChannelType.MAILING_ID) {
        return this.mailPrice > this.calculatedBalance
      }
      return this.smsPrice * this.phonesWithPrefix.phones.length > this.calculatedBalance
    },
  },
  methods: {
    setBalanceAlert () {
      AccountService.getBalance().then((data) => {
        this.balanceAlert = data
      })
    },
    getPlanPrices () {
      PaymentService.getPlanPrices().then(
        (response) => {
          this.smsPriceDataTable = Object.values(response.countriesMaxPricesInfo[0])
          this.mainSmsPrice = response.userCountrySmsPrice
          this.mainCertificateSmsPrice = response.userCertifiedSmsCost
          this.mainMailPrice = response.defaultPlanUser
        },
        () => {},
      )
    },
    confirmSendTest () {
      if (this.campaign.sendings[0].channel_type_id !== this.channelMailing) {
        this.campaign.countryIso = this.phonesWithPrefix.isoCode
      }
      this.$emit('confirm-test', this.phonesWithPrefix)
    },
    updatedPhonesWithPrefix (phonesWithPrefix) {
        this.phonesWithPrefix = phonesWithPrefix
        this.updateSmsPrice()
        this.updateMailPrice()
    },
    updateSmsPrice () {
      this.smsPrice = this.smsPriceDataTable.find((c) => c.iso2 === this.phonesWithPrefix?.isoCode)?.cost || 0
      this.smsPrice += this.campaign.sendings[0].channel?.certified * this.mainCertificateSmsPrice || 0
      this.smsPrice *= this.currentSmsIn?.chunks || 1
    },
    updateMailPrice () {
      this.mailPrice = this.mainMailPrice / 1000
    },
  },
  watch: {
    campaign: {
      handler: function () {
        this.updateSmsPrice()
      },
      deep: true,
    },
  },
}
