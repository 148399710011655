import EventBus from '@/util/EventBus.js'
import { ChannelType } from '@/models/ChannelType'
import { ContactsFileUploadResponse } from '@/classes/ContactsFileUploadResponse'
import { SmsPreview } from '@/classes/preview/SmsPreview'
import { CsvFileConfig } from '@/models/CsvFileConfig'
import ContactSourceService from '@/services/contact-source.service'
import ContactsFileUploadResult from '@/components/ContactSource/ContactsFileUploadResult/ContactsFileUploadResult.vue'
import ButtonMultimedia from '@/components/MultimediaManager/ButtonMultimedia/ButtonMultimedia.vue'
import State from '@/util/State'
import groupService from '@/services/group.service'
import userService from '@/services/user.service'

export default {
  name: 'ContactSource',
  components: {
    ContactsFileUploadResult,
    ButtonMultimedia,
  },
  props: {
    campaign: {
      type: Object,
    },
    componentsData: {
      type: Object,
    },
    groups: {
      type: Array,
    },
    preview: {
      type: Object,
    },
    previewUrl: {
      type: String,
    },
    fields: {
      type: Object,
    },
    emptyRecipients: {
      type: Boolean,
    },
    errors: {
      type: Object,
    },
    channelType: {
      type: Number,
    },
    contactsFileUploadResult: {
      type: Object,
    },
    regex: {
      type: Object,
    },
  },
  data: function () {
    return {
      drawer: false,
      formDataType: {
        sendFile: 'sendFile',
        reparseFile: 'reparseFile',
      },
      sourceKeys: {
        IMPORT_KEY: 'import',
        IMPORT_COPY_PASTE_KEY: 'import-copy-paste',
        CONTACTS_KEY: 'contacts',
      },
      contactsFileUploadResponse: new ContactsFileUploadResponse(),
      csvTextContent: '',
      fileErrors: false,
      fileErrorsText: '',
      isValidCsv: false,
      skipHeaderDisabled: false,
      countryIso: {},
      user: this.$store.getters['auth/getUser'],
      loadingData: false,
      loading: false,
      groupsWithoutRoutes: [],
      noRouteWarningDialog: false,
      confirmContactConsent: false,
      state: new State('state'),
      showConfirmExitNoContactsNotSaved: false,
      addedContactsToSending: false,
    }
  },
  methods: {
    saveState () {
      this.state.setState(this.$data)
    },
    recoverState () {
      this.state.getState(this.$data)
    },
    saveInitialState () {
      this.state.setInitialState(this.$data)
    },
    resetState () {
      this.state.getInitialState(this.$data)
    },
    resetDrawer () {
      this.resetState()
      this.saveState()
    },
    async updateGroupsSource (groups) {
      if (this.campaign.recipientsSource !== this.sourceKeys.CONTACTS_KEY) {
        return
      }
      this.cleanFileUpload()
      if (groups) {
        this.campaign.setGroups(groups)
      }
      const response = await userService.getAvailableFields({ groups: this.campaign.groups })
      this.setAvailableFields('contacts', response)
      // .then(
      //   (response) => {
      //     this.setAvailableFields('contacts', response)
      //   },
      //   () => { },
      // )
      const data = { groups: this.campaign.groups }
      await groupService.listCountriesByGroupsId(data)
      .then(
        (response) => {
          this.campaign.countryIso = response
        },
        () => { },
      )
      await this.loadPreviewData()
    },
    async loadPreviewData () {
      this.loadingData = true
      EventBus.$emit('loadingSendingCost', true)
      await ContactSourceService.loadPreview(this.campaign, this.previewUrl)
        .then(
          (response) => {
            this.campaign.sendings[0].totalRecipients = response.totalRecipients
            this.campaign.costs.sendingCost = response.sendingCost
            this.loadingData = false
            this.$emit('changePreview', response)
          },
          () => {},
        )
        .finally(() => {
          EventBus.$emit('loadingSendingCost', false)
        })
    },
    setAvailableFields (from, data, headers = null) {
      let fields = data
      if (from === 'file') {
        fields = {}
        for (let i = 1; i <= data[0].length; i++) {
          const header = headers[i - 1] ? ' (' + headers[i - 1] + ')' : ''
          fields['COL' + i] = 'COL' + i + ' - ' + data[0][i - 1] + header
        }
      }
      this.fields.availableFields = fields
    },
    validate () {
      if (this.isRecipientsEmpty === 0) {
        EventBus.$emit('showAlert', 'warning', this.$t('Debes seleccionar los destinatarios').toString())
        EventBus.$emit('showDrawer')
        return false
      }

      if (this.campaign.subtype !== 'basic' && this.campaign.sendings[0].automaticConfig.conditions.field === '') {
        EventBus.$emit('showAlert', 'warning', this.$t('Debes seleccionar el campo cita/cumpleaños').toString())
        EventBus.$emit('showDrawer')
        return false
      }
      return true
    },

    changeRecipientsSourceCleanUp () {
      this.cleanFileUpload()
      this.cleanGroups()
      this.campaign.sendings[0].totalRecipients = 0
      this.errors.show = false
      this.errors.textContent = ''
      this.fields.availableFields = {}
      this.$emit('changePreview', new SmsPreview())
    },

    cleanGroups () {
      this.campaign.groups = []
      this.$emit('cleanSelectedGroups')
    },

    clearVariables (text) {
      text = text.replace(this.regex.urlRegExp, this.regex.EXAMPLE_SHORTENED_URL)
      return text
    },

    setFormData (type) {
      const formData = new FormData()
      formData.append('countryIso', this.campaign.sendings[0].csvFileConfig.countryIso)
      formData.append('skipHeader', this.campaign.sendings[0].csvFileConfig.skipHeader.toString())
      formData.append('delimiter', this.campaign.sendings[0].csvFileConfig.delimiter.toString())
      formData.append('channel', this.campaign.sendings[0].channel.type.toString())
      if (this.campaign.sendings[0].channel.type === ChannelType.SMS_ID) {
        formData.append('certified', this.campaign.sendings[0].channel.certified.toString())
        formData.append('message', this.clearVariables(this.campaign.sendings[0].channel.message.toString()))
        formData.append('encoding', this.campaign.sendings[0].channel.encoding.toString())
      } else if (this.campaign.sendings[0].channel.type === ChannelType.MAILING_ID) {
        formData.append('certified', this.campaign.sendings[0].channel.certified.toString())
      }
      formData.append('allowDuplicates', this.campaign.allowDuplicates ? '1' : '0')
      let url = ''

      url = this.setFile(type, formData, url)
      if (type === this.formDataType.reparseFile) {
        this.setFileOptions(formData)
        url = 'reparseFile'
      }
      return {
        formData: formData,
        url: url,
      }
    },

    setFile (type, formData, url) {
      formData.append('filePath', this.campaign.sendings[0].csvFileConfig.databaseFile)
      if (type === this.formDataType.sendFile) {
        formData.append('firstUpload', 'true')
        url = 'uploadFile'
        if (this.csvTextContent !== '') {
          formData.append('csvTextContent', this.csvTextContent)
          url = 'uploadCsvTextContent'
        }
      }
      return url
    },

    setFileOptions (formData) {
      formData.append('fileName', this.campaign.sendings[0].csvFileConfig.databaseFile)
      formData.append('originalFilename', this.campaign.sendings[0].csvFileConfig.originalFilename)
      formData.append(
        'primaryColumn', (typeof this.campaign.sendings[0].csvFileConfig.primaryColumn !== 'undefined')
        ? this.campaign.sendings[0].csvFileConfig.primaryColumn.toString()
        : '0',
      )
      formData.append(
        'externalIdColumn', (typeof this.campaign.sendings[0].csvFileConfig.externalIdColumn !== 'undefined')
        ? '' + this.campaign.sendings[0].csvFileConfig.externalIdColumn + ''
        : '0',
      )
    },

    checkSetValidCsv () {
      this.isValidCsv = this.contactsFileUploadResponse.totalRows - (this.preview.errors.length) > 0
    },

    fillUploadResult (isReparse) {
      this.skipHeaderDisabled = false
      let primaryColumnKey = 'phone'
      if (this.campaign.sendings[0].channel.type === ChannelType.MAILING_ID) {
        primaryColumnKey = 'email'
      }

      this.campaign.sendings[0].csvFileConfig.primaryColumn = primaryColumnKey in this.contactsFileUploadResponse.guessedMap ? this.contactsFileUploadResponse.guessedMap[primaryColumnKey] - 1 : 0
      this.campaign.sendings[0].csvFileConfig.databaseFile = this.contactsFileUploadResponse.fileName
      this.campaign.sendings[0].csvFileConfig.externalIdColumn = this.contactsFileUploadResponse.externalIdColumn
      this.campaign.sendings[0].csvFileConfig.delimiter = this.contactsFileUploadResponse.delimiter
      this.campaign.sendings[0].csvFileConfig.originalFilename = this.contactsFileUploadResponse.originalFilename
      this.campaign.countryIso = this.campaign.sendings[0].csvFileConfig.countryIso

      this.setSkipHeader(isReparse)
      this.fileErrors = false
      if (this.preview.errors.length > 0) {
        this.fileErrors = true
      }
    },

    setSkipHeader (isReparse) {
      if (!isReparse) {
        if (this.contactsFileUploadResponse.filePreview.length === 1) {
          this.skipHeaderDisabled = true
          this.campaign.sendings[0].csvFileConfig.skipHeader = 0
        }
      }
    },

    reparseFile (from) {
      this.fileErrors = false
      if (from === 'primaryColumn') {
        this.campaign.sendings[0].csvFileConfig.externalIdColumn = this.campaign.sendings[0].csvFileConfig.primaryColumn
      }

      const data = this.setFormData(this.formDataType.reparseFile)
      EventBus.$emit('loadingSendingCost', true)
      ContactSourceService.sendFile(data.formData, data.url).then(
        (response) => {
          this.contactsFileUploadResponse = new ContactsFileUploadResponse(response.contactsFileUploadResult)
          this.setAvailableFields('file', this.contactsFileUploadResponse.filePreview, this.contactsFileUploadResponse.headers)
          this.campaign.sendings[0].totalRecipients = response.preview.totalRecipients
          this.$emit('changePreview', response.preview)
          if (this.channelType === 7) {
            this.$emit('changePreviewUploadResponse', this.contactsFileUploadResponse)
          }
          this.checkSetValidCsv()
          setTimeout(function () {
            this.fillUploadResult(true)
          }.bind(this), 500)
        },
        (err) => {
          const error = err.response.data
          EventBus.$emit('showAlert', 'warning', error.errorMessage)
        },
      )
      .finally(() => {
        EventBus.$emit('loadingSendingCost', false)
      })
    },

    cleanFileUpload () {
      this.fileErrors = false
      this.fileErrorsText = ''
      this.csvTextContent = ''
      this.campaign.optOut = ''
      this.campaign.countryIso = ''
      this.contactsFileUploadResponse = new ContactsFileUploadResponse()
      const countryConfiguredPreviously = this.campaign.sendings[0].csvFileConfig.countryIso
      this.campaign.sendings[0].csvFileConfig = new CsvFileConfig()
      this.campaign.sendings[0].csvFileConfig.setCountry(countryConfiguredPreviously)
      this.$emit('changeContactsFileUploadResult', new ContactsFileUploadResponse())
    },

    setCountry () {
      this.campaign.sendings[0].csvFileConfig.countryIso = this.countryIso.value
    },
    isCountryEmpty () {
      return !this.componentsData.countries[this.campaign.sendings[0].csvFileConfig.countryIso] ||
            this.campaign.sendings[0].csvFileConfig.countryIso === '' ||
            this.campaign.sendings[0].csvFileConfig.countryIso === null
    },
    isRecipientsEmpty () {
      return this.preview.totalRecipients === 0
    },
    isGroupsAssignedEmpty () {
      return this.campaign.groups.length === 0
    },
    openDrawer (recipientsSource) {
      this.loadingData = false
      if (recipientsSource === this.sourceKeys.IMPORT_COPY_PASTE_KEY) {
        this.addedContactsToSending = false
      }

      const fileAlreadyUploaded = this.contactsFileUploadResponse.success
      if (recipientsSource === this.sourceKeys.IMPORT_KEY && !fileAlreadyUploaded) {
        EventBus.$emit('showMultimedia', 'csv', false)
      } else {
        this.drawer = true
      }
    },

    checkCloseDrawer () {
      if (this.preview.totalRecipients === 0) {
        this.closeDrawer()
      } else if (this.preview.totalRecipients > 0) {
        this.showConfirmExitNoContactsNotSaved = true
      }
    },

    closeDrawer () {
      this.recoverState()
      this.drawer = false
      this.showConfirmExitNoContactsNotSaved = false
      this.$emit('cancel-drawer')
    },

    async acceptDrawer () {
      this.drawer = false
      await this.reloadPreview()
      this.saveState()
      this.$emit('accept-drawer')
      setTimeout(() => {
        this.confirmContactConsent = false
      }, 1000)
    },

    async confirmDrawer () {
      if (this.campaign.recipientsSource === this.sourceKeys.CONTACTS_KEY) {
        if (this.groupsWithoutRoutes.length) {
          this.noRouteWarningDialog = true
        } else {
          this.acceptDrawer()
        }
      } else {
        this.acceptDrawer()
      }
    },
    async reloadPreview () {
      if (this.campaign.recipientsSource === this.sourceKeys.CONTACTS_KEY) {
        await this.updateGroupsSource()
      } else {
        await this.reparseFile()
      }
    },
    async confirmWithNoRoute () {
      this.noRouteWarningDialog = false
      this.acceptDrawer()
    },
    onCountryChange () {
      if (!this.campaign.sendings[0].csvFileConfig.databaseFile) {
        return
      }

      this.reloadPreview()
    },
    cleanSourceForm () {
      this.confirmChangeRecipientsSource()
    },
    onUpload (uploadResponse) {
      this.addedContactsToSending = true
      this.contactsFileUploadResponse = uploadResponse
      if (!this.drawer) {
        this.drawer = true
      }
    },
    isSelectedRecipientsSource (sourceKey) {
      return this.campaign.recipientsSource === sourceKey
    },
    onNoRouteGroupsChange (groups) {
      this.groupsWithoutRoutes = groups
    },
    async loadSmsPreview (campaign, smsObject, smsPreview) {
      if (!campaign) return
      if (campaign.recipientsSource !== this.sourceKeys.CONTACTS_KEY) {
        if (!this.campaign.sendings[0].csvFileConfig.databaseFile) return
        const formData = new FormData()
        const csvFileConfig = campaign.sendings[0].csvFileConfig
        formData.append('countryIso', csvFileConfig.countryIso)
        formData.append('skipHeader', csvFileConfig.skipHeader.toString())
        formData.append('delimiter', csvFileConfig.delimiter.toString())
        formData.append('channel', '1')
        formData.append('certified', '0')
        formData.append('fileName', csvFileConfig.databaseFile)
        formData.append('filePath', csvFileConfig.databaseFile)
        formData.append('originalFilename', csvFileConfig.originalFilename)
        formData.append('primaryColumn', csvFileConfig.primaryColumn.toString())
        formData.append('externalIdColumn', csvFileConfig.externalIdColumn)
        formData.append('certified', '0')
        formData.append('message', this.clearVariables(smsObject.message.toString()))
        formData.append('encoding', smsObject.encoding)
        ContactSourceService.sendFile(formData, 'reparseFile').then((response) => {
          smsPreview.loadData(response.preview)
        })
      } else {
        const data = { groups: campaign.groups }
        await groupService.listCountriesByGroupsId(data)
        .then(
          (response) => {
            campaign.countryIso = response
          },
        )
        const modifiedCampaign = JSON.parse(JSON.stringify(campaign))
        modifiedCampaign.sendings[0].channel.message = smsObject.message.toString()
        modifiedCampaign.sendings[0].channel.sender = smsObject.sender.toString()
        modifiedCampaign.sendings[0].channel.encoding = smsObject.encoding
        modifiedCampaign.sendings[0].channel.certified = '0'

        await ContactSourceService.loadPreview(modifiedCampaign, 'campaign/sms/preview')
          .then(
            (response) => {
              smsPreview.loadData(response)
            },
          )
      }
    },
    checkESCKey (event) {
      if (event.code === 'Escape' && this.drawer) {
        this.checkCloseDrawer()
      }
    },
    checkClickOutside (event) {
      const drawer = document.getElementById('contactSourceDrawer')
      if (drawer) {
        const bounds = drawer.getBoundingClientRect()
        if (event.x < bounds.x && this.drawer) {
          this.checkCloseDrawer()
        }
      }
    },
  },
  computed: {
    params (nv) {
      return {
        ...this.options,
        searchTerm: this.searchTerm,
        startDate: this.startDate,
        endDate: this.endDate,
      }
    },
    // eslint-disable-next-line complexity
    disableAcceptButton: function () {
      if (this.loadingData || this.preview.loading) {
        return true
      }

      if (!this.confirmContactConsent) {
        return true
      }

      if (this.campaign.subtype !== 'basic' && !this.campaign.sendings[0].automaticConfig.conditions.field.length) {
        return true
      }

      if (this.campaign.sendings[0].channel.type === ChannelType.MAILING_ID) {
        return this.isRecipientsEmpty()
      }

      if (this.campaign.recipientsSource === this.sourceKeys.CONTACTS_KEY) {
        return this.isGroupsAssignedEmpty()
      }



      return this.isCountryEmpty() || this.isRecipientsEmpty()
    },
  },
  created () {
    const campaignId = this.$route.params.id
    if (!campaignId || !this.campaign.countryIso) {
      this.campaign.sendings[0].csvFileConfig.setCountry(this.user.isoCountry.toUpperCase() || this.user.isoTld.toUpperCase())
    }

    this.saveInitialState()
    const that = this
    let storedContacts = localStorage.getItem('contact-phones-new-campaign')
    if (storedContacts) {
      this.campaign.recipientsSource = this.sourceKeys.IMPORT_COPY_PASTE_KEY
      storedContacts = JSON.parse(storedContacts)
      if (storedContacts.countryIso) {
        this.campaign.sendings[0].csvFileConfig.countryIso = storedContacts.countryIso.toUpperCase()
      }
      this.drawer = true
    }

    EventBus.$on('contactSource:showDrawer', this.openDrawer)
    EventBus.$on('contactSource:cleanDrawer', this.changeRecipientsSourceCleanUp)
    EventBus.$on('contactSource:resetDrawer', this.resetDrawer)

    setTimeout(function () {
      that.contactsFileUploadResponse = new ContactsFileUploadResponse(that.contactsFileUploadResult)
    }, 500)

    EventBus.$on('contactSource:LoadAdditionalSmsPreview', this.loadSmsPreview)
    window.addEventListener('keydown', this.checkESCKey)

    // improve this as directive or other
    document.body.addEventListener('click', this.checkClickOutside, true)
  },
  destroyed () {
    EventBus.$off('contactSource:showDrawer', this.openDrawer)
    EventBus.$off('contactSource:cleanDrawer', this.changeRecipientsSourceCleanUp)
    EventBus.$off('contactSource:resetDrawer', this.resetDrawer)
    window.removeEventListener('keydown', this.checkESCKey)
    document.body.removeEventListener('click', this.checkClickOutside)
  },
}
