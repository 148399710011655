import { ChannelType } from '@/models/ChannelType'
import ContactSourceService from '@/services/contact-source.service'
import EventBus from '@/util/EventBus'
import { ContactsFileUploadResponse } from '@/classes/ContactsFileUploadResponse'
import { SmsPreview } from '@/classes/preview/SmsPreview'
import { CsvFileConfig } from '@/models/CsvFileConfig'
import State from '@/util/State'

export default {
  props: {
    campaign: {
      type: Object,
      required: true,
    },
    componentsData: {
      type: Object,
      required: true,
    },
    preview: {
      type: Object,
      required: true,
    },
    fields: {
      type: Object,
      required: true,
    },
    regex: {
      type: Object,
    },
  },
  data () {
    return {
      formDataType: {
        sendFile: 'sendFile',
        reparseFile: 'reparseFile',
      },
      selectedGroupsMultiselect: [],
      contactsFileUploadResponse: new ContactsFileUploadResponse(),
      csvTextContent: '',
      fileErrors: false,
      fileErrorsText: '',
      isValidCsv: false,
      skipHeaderDisabled: false,
      user: this.$store.getters['auth/getUser'],
      state: new State('state'),
    }
  },
  computed: {
    displaySelectCountry: function () {
      return this.campaign.sendings[0].channel.type !== ChannelType.MAILING_ID
    },
    columnsOnRightSectionByChannelType: function () {
      return this.campaign.sendings[0].channel.type === ChannelType.MAILING_ID ? 12 : 8
    },
    countries: function () {
      const list = []
      for (const country in this.componentsData.countries) {
        list.push({ value: country, text: this.componentsData.countries[country] })
      }
      return list
    },
  },
  methods: {
    saveState () {
      this.state.setState(this.$data)
    },
    recoverState () {
      this.state.getState(this.$data)
    },
    saveInitialState () {
      this.state.setInitialState(this.$data)
    },
    resetState () {
      this.state.getInitialState(this.$data)
      this.saveState()
    },
    resetDrawer () {
      this.resetState()
      this.saveState()
    },
    sendFile (csvTextContent) {
      EventBus.$emit('showLoading', true)
      if (csvTextContent) {
        this.csvTextContent = csvTextContent.replace(/^\s*[\r\n]/gm, '')
        if (this.csvTextContent.length && this.csvTextContent.split(/\r|\r\n|\n/).length === 1) {
          this.campaign.sendings[0].csvFileConfig.skipHeader = 0
        }
      }

      const data = this.setFormData(this.formDataType.sendFile)
      EventBus.$emit('loadingSendingCost', true)
      ContactSourceService.sendFile(data.formData, data.url)
        .then(
          (response) => {
            if (response.contactsFileUploadResult) {
              this.campaign.sendings[0].automaticConfig.conditions.field = ''
              this.contactsFileUploadResponse = new ContactsFileUploadResponse(response.contactsFileUploadResult)
              this.$emit('upload', this.contactsFileUploadResponse)
              // this.setAvailableFields('file', this.contactsFileUploadResponse.filePreview)
              this.$emit('changePreview', response.preview)
              this.cleanGroups()
              this.checkSetValidCsv()

              setTimeout(function () {
                this.fillUploadResult(false)
                if (parseInt(this.preview.totalRecipients) === 0) {
                  EventBus.$emit('showAlert', 'danger', this.$t('El archivo seleccionado no tiene contactos válidos o se han dado de baja'))
                }
              }.bind(this), 500)
            } else {
              EventBus.$emit('showAlert', 'danger', this.$t('El archivo seleccionado no tiene contactos válidos o se han dado de baja'))
            }
          },
          () => { },
        )
        .finally(() => {
          EventBus.$emit('loadingSendingCost', false)
          EventBus.$emit('showLoading', false)
        })
    },
    clearVariables (text) {
      text = text.replace(this.regex.urlRegExp, this.regex.EXAMPLE_SHORTENED_URL)
      return text
    },
    setFormData (type) {
      const formData = new FormData()
      formData.append('countryIso', this.campaign.sendings[0].csvFileConfig.countryIso)
      formData.append('skipHeader', this.campaign.sendings[0].csvFileConfig.skipHeader.toString())
      formData.append('delimiter', this.campaign.sendings[0].csvFileConfig.delimiter.toString())
      formData.append('channel', this.campaign.sendings[0].channel.type.toString())
      if (this.campaign.sendings[0].channel.type === ChannelType.SMS_ID) {
        formData.append('certified', this.campaign.sendings[0].channel.certified.toString())
        formData.append('message', this.clearVariables(this.campaign.sendings[0].channel.message.toString()))
        formData.append('encoding', this.campaign.sendings[0].channel.encoding.toString())
      } else if (this.campaign.sendings[0].channel.type === ChannelType.MAILING_ID) {
        formData.append('certified', this.campaign.sendings[0].channel.certified.toString())
      }
      formData.append('sender', this.campaign.sendings[0].channel.sender)
      let url = ''

      url = this.setFile(type, formData, url)
      if (type === this.formDataType.reparseFile) {
        this.setFileOptions(formData)
        url = 'reparseFile'
      }
      return {
        formData: formData,
        url: url,
      }
    },
    setFile (type, formData, url) {
      formData.append('filePath', this.campaign.sendings[0].csvFileConfig.databaseFile)
      if (type === this.formDataType.sendFile) {
        formData.append('firstUpload', 'true')
        url = 'uploadFile'
        if (this.csvTextContent !== '') {
          formData.append('csvTextContent', this.csvTextContent)
          url = 'uploadCsvTextContent'
        }
      }
      return url
    },
    setFileOptions (formData) {
      formData.append('fileName', this.campaign.sendings[0].csvFileConfig.databaseFile)
      formData.append('originalFilename', this.campaign.sendings[0].csvFileConfig.originalFilename)
      formData.append(
        'primaryColumn', (typeof this.campaign.sendings[0].csvFileConfig.primaryColumn !== 'undefined')
        ? this.campaign.sendings[0].csvFileConfig.primaryColumn.toString()
        : '0',
      )
      formData.append(
        'externalIdColumn', (typeof this.campaign.sendings[0].csvFileConfig.externalIdColumn !== 'undefined')
        ? '' + this.campaign.sendings[0].csvFileConfig.externalIdColumn + ''
        : '0',
      )
    },
    checkSetValidCsv () {
      this.isValidCsv = this.contactsFileUploadResponse.totalRows - (this.preview?.errors?.length) > 0
    },
    // setAvailableFields (from, data) {
    //   console.log('from: ', from)
    //   console.log('data: ', data)
    //   let fields = data
    //   if (from === 'file') {
    //     fields = {}
    //     for (let i = 1; i <= data[0].length; i++) {
    //       fields['COL' + i] = 'COL' + i + ' - ' + data[0][i - 1]
    //     }
    //   }
    //   this.fields.availableFields = fields
    // },
    fillUploadResult (isReparse) {
      this.skipHeaderDisabled = false
      let primaryColumnKey = 'phone'
      if (this.campaign.sendings[0].channel.type === ChannelType.MAILING_ID) {
        primaryColumnKey = 'email'
      }

      const guessedMap = this.contactsFileUploadResponse.guessedMap
      this.campaign.sendings[0].csvFileConfig.primaryColumn = primaryColumnKey in guessedMap ? guessedMap[primaryColumnKey] - 1 : 0
      this.campaign.sendings[0].csvFileConfig.databaseFile = this.contactsFileUploadResponse.fileName
      this.campaign.sendings[0].csvFileConfig.externalIdColumn = this.contactsFileUploadResponse.externalIdColumn
      this.campaign.sendings[0].csvFileConfig.delimiter = this.contactsFileUploadResponse.delimiter
      this.campaign.sendings[0].csvFileConfig.originalFilename = this.contactsFileUploadResponse.originalFilename
      this.campaign.countryIso = this.campaign.sendings[0].csvFileConfig.countryIso

      this.setSkipHeader(isReparse)
      this.fileErrors = false
      if (this.preview.errors.length > 0) {
        this.fileErrors = true
      }
    },
    cleanGroups () {
      this.selectedGroupsMultiselect = []
      this.campaign.groups = []
    },
    setSkipHeader (isReparse) {
      if (!isReparse) {
        if (this.contactsFileUploadResponse.filePreview.length === 1 && !this.contactsFileUploadResponse.skipHeader) {
          this.skipHeaderDisabled = true
          this.campaign.sendings[0].csvFileConfig.skipHeader = 0
        }
      }
    },
    cleanFileUpload () {
      this.fileErrors = false
      this.fileErrorsText = ''
      this.csvTextContent = ''
      this.campaign.optOut = ''
      this.campaign.countryIso = ''
      this.contactsFileUploadResponse = new ContactsFileUploadResponse()
      const countryConfiguredPreviously = this.campaign.sendings[0].csvFileConfig.countryIso ||
        this.user.isoCountry.toUpperCase() ||
        this.user.isoTld.toUpperCase()
      this.campaign.sendings[0].csvFileConfig = new CsvFileConfig()
      this.campaign.sendings[0].csvFileConfig.setCountry(countryConfiguredPreviously)
      this.$emit('changePreview', new SmsPreview())
      this.$emit('changeContactsFileUploadResult', new ContactsFileUploadResponse())
    },
  },
  created () {
    this.saveInitialState()
    EventBus.$on('contactSource:saveState', this.saveState)
    EventBus.$on('contactSource:recoverState', this.recoverState)
    EventBus.$on('contactSource:resetDrawer', this.resetDrawer)
    if (!this.campaign.sendings[0].csvFileConfig.countryIso.length) {
      this.campaign.sendings[0].csvFileConfig.setCountry(this.user.isoCountry.toUpperCase() || this.user.isoTld.toUpperCase())
    }
  },
  destroy () {
    EventBus.$off('contactSource:saveState', this.saveState)
    EventBus.$off('contactSource:recoverState', this.recoverState)
    EventBus.$off('contactSource:resetDrawer', this.resetDrawer)
  },
}
