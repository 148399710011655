/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
import { Container } from '../classes/Container'
import { Button } from '../classes/Button'
import { Qr } from '../classes/Qr'
import { Text } from '../classes/Text'
import { Html } from '../classes/Html'
import { CancelSubscription } from '../classes/CancelSubscription'
import { OpenInBrowser } from '../classes/OpenInBrowser'
import { Image } from '../classes/Image'
import { SocialMedia } from '../classes/SocialMedia'
import { Product } from '../classes/Product'
import { TypeComponent } from '../enums/TypeComponent.js'
import { Video } from '../classes/Video'
import { GifComponent } from '../classes/GifComponent'
import { Separator } from '../classes/Separator'
export class ComponentFactory {
  static create (componentData) {
    switch (componentData.type) {
      case TypeComponent.Container:
        return new Container(componentData.slots, componentData.distribution)
      case TypeComponent.Button:
        return new Button()
      case TypeComponent.Qr:
        return new Qr()
      case TypeComponent.Text:
        return new Text()
      case TypeComponent.Html:
        return new Html()
      case TypeComponent.CancelSubscription:
        return new CancelSubscription(componentData.isCountryEu)
      case TypeComponent.OpenInBrowser:
        return new OpenInBrowser()
      case TypeComponent.Image:
        return new Image()
      case TypeComponent.SocialMedia:
        return new SocialMedia()
      case TypeComponent.Product:
        return new Product()
      case TypeComponent.Video:
        return new Video()
      case TypeComponent.Gif:
        return new GifComponent()
      case TypeComponent.Separator:
        return new Separator()
      default:
        throw new Error("ComponentFactory can't create the component")
    }
  }

  static createDuplicate (componentData) {
    const container = new Container(
      componentData.slots,
      componentData.distribution,
      componentData.backgroundColor,
      componentData.paddingTop,
      componentData.paddingBottom,
    )

    return container.duplicate(componentData.slots)
  }
}
