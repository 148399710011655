import { ChannelType } from '@/models/ChannelType'
import campaignService from '../../../services/campaign.service'

export default {
  name: 'CopyPasteContacts',
  props: {
    campaign: {
      type: Object,
      required: true,
    },
    channel: {
      type: Number,
      required: true,
    },
    csvFileConfig: {
      type: Object,
      required: true,
    },
  },
  data: function () {
    return {
      contactsCsvContent: '',
      placeholder: '',
      sourceKeys: {
        IMPORT_KEY: 'import',
        CONTACTS_KEY: 'contacts',
        IMPORT_COPY_PASTE_KEY: 'import-copy-paste',
      },
    }
  },
  methods: {
    handleClick () {
      this.contactsCsvContent = this.cleanSpaces(this.contactsCsvContent)
      this.$emit('addContacts', this.contactsCsvContent)
    },
    cleanSpaces (text) {
      const hSpace = '[^\\S\\r\\n]+'
      const delimiter = this.csvFileConfig.delimiter
      return text.toString()
        .replace(new RegExp(hSpace + delimiter + hSpace, 'gim'), delimiter)
        .replace(new RegExp(delimiter + hSpace, 'gim'), delimiter)
        .replace(new RegExp(hSpace + delimiter, 'gim'), delimiter)
    },
    checkAndSetContactPhones () {
      setTimeout(() => {
        const storedContacts = localStorage.getItem('contact-phones-new-campaign')
        if (storedContacts) {
          const phones = storedContacts ? JSON.parse(storedContacts).phones : []
          const phonesWithSpaces = phones.join('\n')
          this.contactsCsvContent = phonesWithSpaces
          localStorage.removeItem('contact-phones-new-campaign')
        }
      }, 1000)
    },
    setPlaceholder () {
      switch (this.channel) {
        case ChannelType.SMS_ID:
          this.placeholder = '612121212\n634343434\n656565656'
          break
        case ChannelType.VOICE_INTERACTIVE_ID:
          this.placeholder = '612121212\n634343434\n656565656'
          break
        case ChannelType.MAILING_ID:
          this.placeholder = 'email@mail.com\nemail2.@mail.com\nemail3@mail.com'
          break
      }
    },
    emptyContactCsvContent () {
      this.contactsCsvContent = ''
    },
    getPastedContacts () {
      if (this.campaign.sendings[0].id && this.campaign.recipientsSource === this.sourceKeys.IMPORT_COPY_PASTE_KEY) {
        const params = {
          sendingId: this.campaign.sendings[0].id,
        }
        campaignService.getSmsContacts(params)
          .then(
            result => {
              this.contactsCsvContent = result
            },
            _ => {
              console.log('No contacts found')
            },
          )
      }
    },
  },
  mounted () {
    this.getPastedContacts()
  },

  created () {
    this.checkAndSetContactPhones()
    this.setPlaceholder()
  },
}
